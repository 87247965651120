import React from 'react'
import { Helmet } from 'react-helmet'

const TITLE = 'Careers and Jobs at Fourthwall'

type Props = {
  title?: string
  ogImage?: string
}

export const SEO = ({ title, ogImage }: Props) => (
  <Helmet defaultTitle={TITLE} titleTemplate={`%s - ${TITLE}`}>
    <html lang="en" />
    <title>{title}</title>

    <meta
      name="description"
      content="If you're talented, hardworking and love helping others, we'd like to talk to you about joining our team."
    />

    {/* og */}
    <meta property="og:type" content="website" />
    <meta property="og:url" content="https://jobs.fourthwall.com/" />
    <meta property="og:title" content={title ? `${title} - ${TITLE}` : TITLE} />
    <meta
      property="og:description"
      content="If you're talented, hardworking and love helping others, we'd like to talk to you about joining our team."
    />
    <meta property="og:image" content={ogImage ? ogImage : 'https://jobs.fourthwall.com/images/social.jpg'} />

    {/* twitter */}
    <meta property="twitter:card" content="summary_large_image" />
    <meta property="twitter:title" content={title ? `${title} - ${TITLE}` : TITLE} />
    <meta
      property="twitter:description"
      content="If you're talented, hardworking and love helping others, we'd like to talk to you about joining our team."
    />
    <meta property="twitter:image" content={ogImage ? ogImage : 'https://jobs.fourthwall.com/images/social.jpg'} />

    <script src="https://unpkg.com/flickity@2/dist/flickity.pkgd.min.js" />
    <script src="/js/theme.js" />
  </Helmet>
)
