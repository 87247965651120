import React from 'react'

export const Footer = () => (
  <footer className="theme-footer">
    <div className="container-full">
      {/*desktop footer*/}
      <div className="row desktop-view">
        <div className="col-12 col-md-7">
          <div className="footer-left-box">
            <div className="footer-box footer-brand">
              <figure>
                <a href="/">
                  <svg
                    width={48}
                    height={30}
                    viewBox="0 0 48 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M17.9201 0H14.4422C8.55106 0 6.01461 1.06409 4.95087 6.26112L4.62349 7.65295H1.63693L0.61398 12.441H3.64133L0 29.6704H6.75061L10.3919 12.441H15.2602L16.2835 7.65295H11.4149L11.6196 6.54771C12.0289 4.87034 12.5606 4.74762 14.9743 4.74762H16.8971L17.9201 0ZM35.6444 7.65284H28.6895L26.1126 22.5906L23.2073 7.65284H16.2935L21.3664 29.6703H29.2216L32.1673 13.4231L35.1128 29.6703H42.9271L48 7.65284H41.0862L38.1809 22.5906L35.6444 7.65284Z"
                      fill="#FDFCFB"
                    />
                  </svg>
                </a>
              </figure>
            </div>
            <div className="footer-box footer-address">
              <address>
                <p>
                  Poland office <br />
                  Kochanowskiego 4/9 <br />
                  60-844, Poznan
                </p>
              </address>
            </div>
            <div className="footer-box footer-address">
              <address>
                <p>
                  US office
                  <br />
                  212 Pacific Ave
                  <br />
                  Venice, CA 90291
                </p>
              </address>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-5">
          <div className="footer-right-box">
            <div className="footer-box footer-social-link">
              <nav className="social-link">
                <ul>
                  <li>
                    <a href="//fourthwall.com/" target="_blank" rel="noopener noreferrer">
                      Fourthwall.com
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/lifeatfourthwall/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Instagram
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/company/fw/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Linkedin
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.facebook.com/Life-at-FW-108516150760905/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Facebook
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
      {/*desktop footer*/}
      {/*mobile footer*/}
      <div className="row visible-mobile">
        <div className="col-12 d-flex footer-small-main">
          <div className="footer-left-box">
            <div className="footer-box footer-address">
              <address>
                <p>
                  Poland office <br />
                  Kochanowskiego 4/9 <br />
                  60-844, Poznan
                </p>
              </address>
            </div>
            <div className="footer-box footer-address">
              <address>
                <p>
                  US office
                  <br />
                  212 Pacific Ave
                  <br />
                  Venice, CA 90291
                </p>
              </address>
            </div>
            <div className="footer-box footer-social-link">
              <nav className="social-link">
                <ul>
                  <li>
                    <a href="//fourthwall.com/" target="_blank" rel="noopener noreferrer">
                      Fourthwall.com
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/lifeatfw/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Instagram
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/company/fw/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Linkedin
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.facebook.com/Life-at-FW-108516150760905/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Facebook
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
          <div className="footer-right-box">
            <div className="footer-box footer-brand">
              <figure>
                <a href="/">
                  <svg
                    width={48}
                    height={30}
                    viewBox="0 0 48 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M17.9201 0H14.4422C8.55106 0 6.01461 1.06409 4.95087 6.26112L4.62349 7.65295H1.63693L0.61398 12.441H3.64133L0 29.6704H6.75061L10.3919 12.441H15.2602L16.2835 7.65295H11.4149L11.6196 6.54771C12.0289 4.87034 12.5606 4.74762 14.9743 4.74762H16.8971L17.9201 0ZM35.6444 7.65284H28.6895L26.1126 22.5906L23.2073 7.65284H16.2935L21.3664 29.6703H29.2216L32.1673 13.4231L35.1128 29.6703H42.9271L48 7.65284H41.0862L38.1809 22.5906L35.6444 7.65284Z"
                      fill="#FDFCFB"
                    />
                  </svg>
                </a>
              </figure>
            </div>
          </div>
        </div>
      </div>
      {/*desktop footer*/}
    </div>
  </footer>
)
