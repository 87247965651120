import { Link } from 'gatsby'
import React from 'react'

export const Header = () => (
  <header className="theme-header ha-header-white">
    <div className="theme-header-main">
      <div className="container-full">
        <div className="row">
          <div className="col-12">
            <div className="header-col">
              <figure className="header-brand">
                <Link to="/">
                  <svg
                    width={149}
                    height={32}
                    viewBox="0 0 149 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="brand-logo"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M117.113 15.4233L115.002 26.282L113.131 15.4233H108.08L106.238 26.282L104.129 15.4233H99.1069L102.791 31.4294H108.466L110.606 19.6186L112.744 31.4294H118.45L122.134 15.4233H117.113Z"
                      fill="#10110D"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M127.635 28.3912C129.595 28.3912 131.081 27.5585 131.081 24.6135V24.2264C127.664 24.5233 125.376 24.9706 125.376 26.6655C125.376 27.7067 126.357 28.3912 127.635 28.3912M128.259 18.1569C126.684 18.1569 125.584 18.9602 125.406 20.5077H120.83C121.038 17.5031 123.951 14.8248 128.674 14.8248C133.25 14.8248 135.895 16.6693 135.805 21.5496L135.746 25.0297C135.717 27.1128 135.805 29.9682 136.102 31.3368H131.289L131.23 29.403C130.041 31.099 128.14 31.7233 125.851 31.7233C122.821 31.7233 120.325 29.9385 120.325 27.0227C120.325 23.0956 124.753 21.7862 130.963 21.3405V20.7159C130.963 18.8419 130.012 18.1569 128.259 18.1569"
                      fill="#10110D"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M137.073 31.337H141.974V9.7687H137.073V31.337Z"
                      fill="#10110D"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M143.306 31.337H148.209V9.7687H143.306V31.337Z"
                      fill="#10110D"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M10.619 9.76737H13.1445L12.4015 13.2185H11.0051C9.25219 13.2185 8.86613 13.3079 8.5693 14.5272L8.42024 15.3305H11.9562L11.2132 18.8113H7.67726L5.03261 31.3367H0.129883L2.77479 18.8113H0.576538L1.31952 15.3305H3.48862L3.72563 14.3188C4.49852 10.541 6.34036 9.76737 10.619 9.76737"
                      fill="#10110D"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M17.4125 18.723C14.738 18.723 13.3699 21.4898 13.3699 24.5534C13.3699 26.7855 14.3516 27.9152 16.1937 27.9152C18.8678 27.9152 20.2346 25.1486 20.2346 22.0838C20.2346 19.853 19.2531 18.723 17.4125 18.723M17.7682 14.9142C22.0473 14.9142 25.2851 17.145 25.2851 21.8459C25.2851 25.9517 22.7005 31.7242 15.8662 31.7242C11.5886 31.7242 8.31885 29.4916 8.31885 24.7915C8.31885 20.6865 10.9344 14.9142 17.7682 14.9142"
                      fill="#10110D"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M28.6908 31.7235C25.7798 31.7235 24.0859 30.2356 24.0859 27.082C24.0859 26.2786 24.2051 25.4167 24.4122 24.4052L26.3141 15.3303H31.2163L29.4338 23.7202C29.2553 24.4938 29.1658 25.1479 29.1658 25.7131C29.1658 27.2312 29.8201 28.0048 31.1869 28.0048C33.1479 28.0048 34.5444 26.5769 35.1386 23.7202L36.9208 15.3303H41.8236L38.4367 31.3369H33.6534L34.0985 29.2843C33.0888 30.5633 31.4244 31.7235 28.6908 31.7235"
                      fill="#10110D"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M65.936 17.4727C67.0055 16.1336 68.5502 15.0039 71.2248 15.0039C74.1364 15.0039 75.8294 16.4907 75.8294 19.6751C75.8294 20.4774 75.7113 21.3703 75.5034 22.3518L73.6018 31.3369H68.6993L70.4816 23.0062C70.6603 22.2326 70.7495 21.5785 70.7495 21.0132C70.7495 19.5259 70.0958 18.7822 68.7287 18.7822C66.7981 18.7822 65.3712 20.1508 64.7773 23.0062L62.9948 31.3369H58.0918L62.6673 9.76714H67.571L65.936 17.4727Z"
                      fill="#10110D"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M57.3235 18.8118H60.8591L61.6018 15.3309H58.0664L59.0173 10.9577H54.1145L53.2202 15.1522H52.6938C50.258 15.1522 48.4453 16.5505 47.316 18.2759L47.9399 15.3309H43.1862L39.7993 31.3371H44.702L46.4255 23.2155C46.9006 20.8344 48.3561 20.1212 50.4362 20.1212C50.9407 20.1212 51.5348 20.1509 52.129 20.2699L50.7277 26.8445C50.5791 27.558 50.4894 28.1826 50.4894 28.6887C50.4894 30.8604 51.8869 31.3371 55.0355 31.3371H58.215L58.9574 27.7958H57.5313C56.2236 27.7958 55.7191 27.6184 55.7191 26.7854C55.7191 26.5468 55.7485 26.309 55.8076 25.9823L57.3235 18.8118Z"
                      fill="#10110D"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M83.516 11.1707C83.0269 11.1707 82.6299 11.5768 82.6299 12.0775C82.6299 12.5785 83.0269 12.9845 83.516 12.9845C84.0054 12.9845 84.4024 12.5785 84.4024 12.0775C84.4024 11.5768 84.0054 11.1707 83.516 11.1707"
                      fill="#10110D"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M96.2164 12.0776C96.2164 11.5766 95.8198 11.1706 95.3302 11.1706C94.8409 11.1706 94.4443 11.5766 94.4443 12.0776C94.4443 12.5784 94.8409 12.9844 95.3302 12.9844C95.8198 12.9844 96.2164 12.5784 96.2164 12.0776"
                      fill="#10110D"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M82.4087 14.9491C81.5116 14.9491 80.7842 15.6933 80.7842 16.6116C80.7842 17.5299 81.5116 18.274 82.4087 18.274C83.3061 18.274 84.0332 17.5299 84.0332 16.6116C84.0332 15.6933 83.3061 14.9491 82.4087 14.9491"
                      fill="#10110D"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M96.3638 14.9491C95.4664 14.9491 94.7393 15.6933 94.7393 16.6116C94.7393 17.5299 95.4664 18.274 96.3638 18.274C97.2609 18.274 97.9883 17.5299 97.9883 16.6116C97.9883 15.6933 97.2609 14.9491 96.3638 14.9491"
                      fill="#10110D"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M89.5253 0.117863C82.4284 0.117863 76.6753 5.87837 76.6753 12.9844C76.6753 20.0905 82.4284 25.8512 89.5253 25.8512C96.6222 25.8512 102.375 20.0905 102.375 12.9844C102.375 5.87837 96.6222 0.117863 89.5253 0.117863M89.5253 0.885863C96.188 0.885863 101.608 6.31332 101.608 12.9844C101.608 19.6558 96.188 25.0832 89.5253 25.0832C82.8625 25.0832 77.4423 19.6558 77.4423 12.9844C77.4423 6.31332 82.8625 0.885863 89.5253 0.885863"
                      fill="#10110D"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M89.5254 21.2987C88.3017 21.2987 87.1402 20.8305 86.2548 19.9806C86.1019 19.8336 86.0968 19.5907 86.2433 19.4376C86.3901 19.2845 86.633 19.2797 86.7856 19.4264C87.5276 20.1385 88.5004 20.5307 89.5254 20.5307C90.5503 20.5307 91.5234 20.1385 92.2654 19.4264C92.4183 19.2792 92.6609 19.2845 92.8077 19.4376C92.9542 19.5907 92.949 19.8336 92.7961 19.9806C91.9108 20.8305 90.7493 21.2987 89.5254 21.2987"
                      fill="#10110D"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M84.187 8.60749H82.698C82.4863 8.60749 82.3145 8.43571 82.3145 8.22349C82.3145 8.01126 82.4863 7.83949 82.698 7.83949H84.187C84.3987 7.83949 84.5705 8.01126 84.5705 8.22349C84.5705 8.43571 84.3987 8.60749 84.187 8.60749"
                      fill="#10110D"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M96.1486 8.60749H94.6599C94.4482 8.60749 94.2764 8.43571 94.2764 8.22349C94.2764 8.01126 94.4482 7.83949 94.6599 7.83949H96.1486C96.3603 7.83949 96.5321 8.01126 96.5321 8.22349C96.5321 8.43571 96.3603 8.60749 96.1486 8.60749"
                      fill="#10110D"
                    />
                  </svg>
                </Link>
              </figure>

              <div className="header-btn-warp">
                <div className="fw-com">
                  <a href="//fourthwall.com/" target="_blank" rel="noopener noreferrer">
                    Fourthwall.com ↗
                  </a>
                </div>

                <Link to="#positions" className="btn btn-primary scroll-click">
                  See open positions
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
)

export const HeaderStatic = () => (
  <header className="theme-header-hero ha-header-white">
    <div className="theme-header-static">
      <div className="container-full">
        <div className="row">
          <div className="col-12">
            <div className="header-col">
              <figure className="header-brand">
                <Link to="/">
                  <svg
                    width={149}
                    height={32}
                    viewBox="0 0 149 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="brand-logo"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M117.113 15.4233L115.002 26.282L113.131 15.4233H108.08L106.238 26.282L104.129 15.4233H99.1069L102.791 31.4294H108.466L110.606 19.6186L112.744 31.4294H118.45L122.134 15.4233H117.113Z"
                      fill="#10110D"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M127.635 28.3912C129.595 28.3912 131.081 27.5585 131.081 24.6135V24.2264C127.664 24.5233 125.376 24.9706 125.376 26.6655C125.376 27.7067 126.357 28.3912 127.635 28.3912M128.259 18.1569C126.684 18.1569 125.584 18.9602 125.406 20.5077H120.83C121.038 17.5031 123.951 14.8248 128.674 14.8248C133.25 14.8248 135.895 16.6693 135.805 21.5496L135.746 25.0297C135.717 27.1128 135.805 29.9682 136.102 31.3368H131.289L131.23 29.403C130.041 31.099 128.14 31.7233 125.851 31.7233C122.821 31.7233 120.325 29.9385 120.325 27.0227C120.325 23.0956 124.753 21.7862 130.963 21.3405V20.7159C130.963 18.8419 130.012 18.1569 128.259 18.1569"
                      fill="#10110D"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M137.073 31.337H141.974V9.7687H137.073V31.337Z"
                      fill="#10110D"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M143.306 31.337H148.209V9.7687H143.306V31.337Z"
                      fill="#10110D"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M10.619 9.76737H13.1445L12.4015 13.2185H11.0051C9.25219 13.2185 8.86613 13.3079 8.5693 14.5272L8.42024 15.3305H11.9562L11.2132 18.8113H7.67726L5.03261 31.3367H0.129883L2.77479 18.8113H0.576538L1.31952 15.3305H3.48862L3.72563 14.3188C4.49852 10.541 6.34036 9.76737 10.619 9.76737"
                      fill="#10110D"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M17.4125 18.723C14.738 18.723 13.3699 21.4898 13.3699 24.5534C13.3699 26.7855 14.3516 27.9152 16.1937 27.9152C18.8678 27.9152 20.2346 25.1486 20.2346 22.0838C20.2346 19.853 19.2531 18.723 17.4125 18.723M17.7682 14.9142C22.0473 14.9142 25.2851 17.145 25.2851 21.8459C25.2851 25.9517 22.7005 31.7242 15.8662 31.7242C11.5886 31.7242 8.31885 29.4916 8.31885 24.7915C8.31885 20.6865 10.9344 14.9142 17.7682 14.9142"
                      fill="#10110D"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M28.6908 31.7235C25.7798 31.7235 24.0859 30.2356 24.0859 27.082C24.0859 26.2786 24.2051 25.4167 24.4122 24.4052L26.3141 15.3303H31.2163L29.4338 23.7202C29.2553 24.4938 29.1658 25.1479 29.1658 25.7131C29.1658 27.2312 29.8201 28.0048 31.1869 28.0048C33.1479 28.0048 34.5444 26.5769 35.1386 23.7202L36.9208 15.3303H41.8236L38.4367 31.3369H33.6534L34.0985 29.2843C33.0888 30.5633 31.4244 31.7235 28.6908 31.7235"
                      fill="#10110D"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M65.936 17.4727C67.0055 16.1336 68.5502 15.0039 71.2248 15.0039C74.1364 15.0039 75.8294 16.4907 75.8294 19.6751C75.8294 20.4774 75.7113 21.3703 75.5034 22.3518L73.6018 31.3369H68.6993L70.4816 23.0062C70.6603 22.2326 70.7495 21.5785 70.7495 21.0132C70.7495 19.5259 70.0958 18.7822 68.7287 18.7822C66.7981 18.7822 65.3712 20.1508 64.7773 23.0062L62.9948 31.3369H58.0918L62.6673 9.76714H67.571L65.936 17.4727Z"
                      fill="#10110D"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M57.3235 18.8118H60.8591L61.6018 15.3309H58.0664L59.0173 10.9577H54.1145L53.2202 15.1522H52.6938C50.258 15.1522 48.4453 16.5505 47.316 18.2759L47.9399 15.3309H43.1862L39.7993 31.3371H44.702L46.4255 23.2155C46.9006 20.8344 48.3561 20.1212 50.4362 20.1212C50.9407 20.1212 51.5348 20.1509 52.129 20.2699L50.7277 26.8445C50.5791 27.558 50.4894 28.1826 50.4894 28.6887C50.4894 30.8604 51.8869 31.3371 55.0355 31.3371H58.215L58.9574 27.7958H57.5313C56.2236 27.7958 55.7191 27.6184 55.7191 26.7854C55.7191 26.5468 55.7485 26.309 55.8076 25.9823L57.3235 18.8118Z"
                      fill="#10110D"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M83.516 11.1707C83.0269 11.1707 82.6299 11.5768 82.6299 12.0775C82.6299 12.5785 83.0269 12.9845 83.516 12.9845C84.0054 12.9845 84.4024 12.5785 84.4024 12.0775C84.4024 11.5768 84.0054 11.1707 83.516 11.1707"
                      fill="#10110D"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M96.2164 12.0776C96.2164 11.5766 95.8198 11.1706 95.3302 11.1706C94.8409 11.1706 94.4443 11.5766 94.4443 12.0776C94.4443 12.5784 94.8409 12.9844 95.3302 12.9844C95.8198 12.9844 96.2164 12.5784 96.2164 12.0776"
                      fill="#10110D"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M82.4087 14.9491C81.5116 14.9491 80.7842 15.6933 80.7842 16.6116C80.7842 17.5299 81.5116 18.274 82.4087 18.274C83.3061 18.274 84.0332 17.5299 84.0332 16.6116C84.0332 15.6933 83.3061 14.9491 82.4087 14.9491"
                      fill="#10110D"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M96.3638 14.9491C95.4664 14.9491 94.7393 15.6933 94.7393 16.6116C94.7393 17.5299 95.4664 18.274 96.3638 18.274C97.2609 18.274 97.9883 17.5299 97.9883 16.6116C97.9883 15.6933 97.2609 14.9491 96.3638 14.9491"
                      fill="#10110D"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M89.5253 0.117863C82.4284 0.117863 76.6753 5.87837 76.6753 12.9844C76.6753 20.0905 82.4284 25.8512 89.5253 25.8512C96.6222 25.8512 102.375 20.0905 102.375 12.9844C102.375 5.87837 96.6222 0.117863 89.5253 0.117863M89.5253 0.885863C96.188 0.885863 101.608 6.31332 101.608 12.9844C101.608 19.6558 96.188 25.0832 89.5253 25.0832C82.8625 25.0832 77.4423 19.6558 77.4423 12.9844C77.4423 6.31332 82.8625 0.885863 89.5253 0.885863"
                      fill="#10110D"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M89.5254 21.2987C88.3017 21.2987 87.1402 20.8305 86.2548 19.9806C86.1019 19.8336 86.0968 19.5907 86.2433 19.4376C86.3901 19.2845 86.633 19.2797 86.7856 19.4264C87.5276 20.1385 88.5004 20.5307 89.5254 20.5307C90.5503 20.5307 91.5234 20.1385 92.2654 19.4264C92.4183 19.2792 92.6609 19.2845 92.8077 19.4376C92.9542 19.5907 92.949 19.8336 92.7961 19.9806C91.9108 20.8305 90.7493 21.2987 89.5254 21.2987"
                      fill="#10110D"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M84.187 8.60749H82.698C82.4863 8.60749 82.3145 8.43571 82.3145 8.22349C82.3145 8.01126 82.4863 7.83949 82.698 7.83949H84.187C84.3987 7.83949 84.5705 8.01126 84.5705 8.22349C84.5705 8.43571 84.3987 8.60749 84.187 8.60749"
                      fill="#10110D"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M96.1486 8.60749H94.6599C94.4482 8.60749 94.2764 8.43571 94.2764 8.22349C94.2764 8.01126 94.4482 7.83949 94.6599 7.83949H96.1486C96.3603 7.83949 96.5321 8.01126 96.5321 8.22349C96.5321 8.43571 96.3603 8.60749 96.1486 8.60749"
                      fill="#10110D"
                    />
                  </svg>
                </Link>
              </figure>

              <div className="header-btn-warp">
                <div className="fw-com">
                  <a href="//fourthwall.com/" target="_blank" rel="noopener noreferrer">
                    Fourthwall.com ↗
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
)
