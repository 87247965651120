import React from 'react'

export const SocialsSection = () => (
  <div className="section-social-bottom">
    <div className="container-full">
      <div className="social-header">
        <div className="row">
          <div className="col-12 col-md-6">
            <h5>
              <a
                href="http://www.instagram.com/lifeatfourthwall"
                target="_blank"
                rel="noopener noreferrer"
              >
                @lifeatfourthwall
              </a>
            </h5>
          </div>
          <div className="col-12 col-md-6 text-right">
            <h5>
              <a href="mailto:jobs@fourthwall.com">jobs@fourthwall.com</a>
            </h5>
          </div>
        </div>
      </div>
    </div>
    <div className="container-max-fluid gutter-half">
      <div className="row">
        <div className="col-12 col-md-12 no-gutter">
          <div className="social-box-grid">
            <div id="instagram-feed1">
              <div className="instagram_gallery">
                <div className="insta-item">
                  <img src="/images/social-1.jpg" alt="" />
                </div>
                <div className="insta-item">
                  <img src="/images/social-2.jpg" alt="" />
                </div>
                <div className="insta-item">
                  <img src="/images/social-3.jpg" alt="" />
                </div>
                <div className="insta-item">
                  <img src="/images/social-4.jpg" alt="" />
                </div>
                <div className="insta-item">
                  <img src="/images/social-5.jpg" alt="" />
                </div>
                <div className="insta-item">
                  <img src="/images/social-6.jpg" alt="" />
                </div>
                <div className="insta-item">
                  <img src="/images/social-7.jpg" alt="" />
                </div>
                <div className="insta-item">
                  <img src="/images/social-8.jpg" alt="" />
                </div>
                <div className="insta-item">
                  <img src="/images/social-9.jpg" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)
